import React from 'react'
import { graphql } from 'gatsby'
import { CollectionTypes } from '../types'
import Layout from '../layout'
import { Collection } from '../components/templates'

const Artists: React.FC<Props> = ({ data, location }) => (
  <Layout pageName="Artists" location={location}>
    <Collection
      collectionItems={data.allContentfulArtist.nodes.map((contentfulArtistNode) => {
        return {
          title: contentfulArtistNode.name,
          slug: contentfulArtistNode.slug,
          gatsbyImageData: contentfulArtistNode.image.gatsbyImageData
        }
      })}
      collectionType={CollectionTypes.Artists}
      columns={4}
    />
  </Layout>
)

export default Artists

export const query = graphql`
  query ArtistsPageQuery {
    allContentfulArtist(sort: { fields: name }) {
      nodes {
        name
        slug
        image {
          gatsbyImageData(layout: CONSTRAINED, height: 840)
        }
      }
    }
  }
`

interface Props {
  data: {
    allContentfulArtist: {
      nodes: ContentfulArtistNode[]
    }
  }
  location: any
}

interface ContentfulArtistNode {
  name: string
  slug: string
  image: {
    gatsbyImageData: any
  }
}
