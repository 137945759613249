export enum CollectionTypes {
  Artists = 'artists',
  Blog = 'blog'
}

export type CollectionItem = {
  title: string
  slug: string
  gatsbyImageData: any
}

export type ShopItemType = {
  slug: string
  productType: string
  variants: ShopifyVariant[]
  title: string
  subtitle?: string
  gatsbyImageData: string
  availableForSale?: boolean
  latest?: boolean
  metafields?: ShopifyMetafield[]
  minimumPrice: string
  maximumPrice: string
}

export type ShopifyVariant = {
  shopifyId: string
  title: string
  price: string
  compareAtPrice?: string
  availableForSale: boolean
}

export type ShopifyMetafield = {
  namespace: string
  key: string
  value: string
}
